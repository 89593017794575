// consumer_t-us-rank_rankUserList 职级员工查询
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-rank-controller/rankUserListUsingPOST
const consumer_tUsRank_rankUserList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-us-rank/rankUserList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_tUsRank_rankUserList;
// consumer_t-us-rank_rankPageable 职级分页
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-rank-controller/rankPageableUsingPOST
const consumer_tUsRank_rankPageable = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-us-rank/rankPageable',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_tUsRank_rankPageable;
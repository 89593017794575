import Pagination from '@/component/pagination/index.vue'
import Transfer from '@/page/admin/company-management/staff-management/component/transfer/index.vue'
import delDialog from '@/page/admin/company-management/staff-management/component/del-dialog/index.vue'
import consumer_tUsRank_rankPageable from '@/lib/data-service/haolv-default/consumer_t-us-rank_rankPageable'
import consumer_tUsRank_rankSave from '@/lib/data-service/haolv-default/consumer_t-us-rank_rankSave'
import consumer_tUsRank_rankDel from '@/lib/data-service/haolv-default/consumer_t-us-rank_rankDel'
import consumer_tUsRank_rankUserList from '@/lib/data-service/haolv-default/consumer_t-us-rank_rankUserList'
import consumer_tUsRank_rankMove from '@/lib/data-service/haolv-default/consumer_t-us-rank_rankMove'
import consumer_department_getDepartmentUsers from '@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers'
export default {
    data () {
        return {
            loading: false,
            params: {
                total: 100,
                pageSize: 10,
                currentPage: 1
            },
            tableData: [],
            newParams: {
                rankName: '',
                id: ''
            },
            rule: {
                rankName: [
                    { required: true, message: '请填写职级名称', trigger: 'blur' },
                ]
            },
            dialogType: 'add', // 新增add 编辑edit
            dialogStatus: {
                add: '新增职级',
                edit: '编辑职级'
            },
            dialogVisible: false,
            delParams: {
                companyId: '',
                rankId: ''
            },
            dialogDelVisible: false,
            changeParams: {
                companyId: 0,
                toRankId: 0,
                userIds: ''
            },
            dialogSelectVisible: false,
            cascadeList: [],  // 穿梭框用
            currentActiveList: [], // 当前穿梭框选中的
        }
    },
    components: {
        Pagination,
        Transfer,
        delDialog
    },
    created () {},
    mounted () {

    },
    activated () {
        this.getRankList()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getRankList() {
            this.loading = true
            consumer_tUsRank_rankPageable(this.params).then(res => {
                this.loading = false
                this.tableData = res.datas.list
                this.params.total = res.datas.totalCount
            }).catch(() => {
                this.loading = false
            })
        },
        travelPolicyFormatter(val) {
            let travelPolicyText = ''
            if (val.travelPolicy === 0) {
                travelPolicyText = '未设置'
            } else {
                travelPolicyText = '已设置'
            }
            return travelPolicyText
        },
        handleAdd(val) {
            this.dialogType = 'add'
            this.reactNewParams()
            this.dialogVisible = true
            this.$nextTick(() => {
                this.$refs['form'].clearValidate()
            })
        },
        submitAdd() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    consumer_tUsRank_rankSave(this.newParams).then(res => {
                        this.loading = false
                        this.$message({
                            message: '新增成功',
                            type: 'success'
                        });
                        this.dialogVisible = false
                        this.getRankList()
                    }).catch(() => {
                        this.loading = false
                    })
                }
            })
        },
        handleEdit(val) {
            this.dialogType = 'edit'
            this.reactNewParams()
            this.newParams = {
                rankName: val.rankName,
                id: val.rankId
            }
            this.dialogVisible = true
            this.$nextTick(() => {
                this.$refs['form'].clearValidate()
            })
        },
        submitEdit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    consumer_tUsRank_rankSave(this.newParams).then(res => {
                        this.loading = false
                        this.$message({
                            message: '保存成功',
                            type: 'success'
                        });
                        this.dialogVisible = false
                        this.getRankList()
                    }).catch(() => {
                        this.loading = false
                    })
                }
            })
        },
        reactNewParams() {
            this.newParams ={
                rankName: '',
                id: ''
            }
        },
        handleDel(val) {
            this.dialogDelVisible = true
            this.delParams = {
                companyId: val.companyId,
                rankId: val.rankId
            }
        },
        submitDel() {
            this.loading = true
            consumer_tUsRank_rankDel(this.delParams).then(res => {
                this.loading = false
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.dialogDelVisible = false
                if (this.tableData.length <= 1 && this.params.currentPage !== 1) {
                    this.params.currentPage--
                }
                this.getRankList()
            }).catch(() => {
                this.loading = false
            })
        },
        async handleSelect(val) {
            let reduceDataFunc = (data) => {
                data.map((m, i) => {
                    m.children = m.deptList
                    m.userList = m.staffList
                    delete m.deptList
                    delete m.staffList
                    if (m.userList && m.userList.length) {
                        m.userList.forEach(value => {
                            value.id = value.subStaffId
                            value.name = value.subStaffName
                        })
                    }
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children)
                    }
                })
            }

            this.reactChangeParams()
            this.changeParams = {
                companyId: val.companyId,
                toRankId: val.rankId,
                userIds: ''
            }
            let result = await consumer_department_getDepartmentUsers({companyId: val.companyId})
            let cascadeList = result.datas
            reduceDataFunc(cascadeList)
            /*cascadeList.forEach(value => {
                value.userId = value.subUserId
                value.userName = value.subUserName
            })*/
            this.cascadeList = cascadeList

            let res = await consumer_tUsRank_rankUserList({companyId: val.companyId, rankId: val.rankId})
            this.dialogSelectVisible = true
            let list = res.datas
            let currentActiveList = []
            list.forEach(value => {
                currentActiveList.push(value.userId)
            })
            this.currentActiveList = currentActiveList
        },
        submitChange() {
            this.changeParams.userIds = this.currentActiveList.join(',')
            this.loading = true
            consumer_tUsRank_rankMove(this.changeParams).then(res => {
                this.loading = false
                this.$message({
                    message: '绑定成功',
                    type: 'success'
                });
                this.dialogSelectVisible = false
                this.getRankList()
            }).catch(() => {
                this.loading = false
            })
        },
        reactChangeParams() {
            this.changeParams = {
                companyId: 0,
                toRankId: 0,
                userIds: ''
            }
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
